import { ShopOutlined, DollarOutlined, BarChartOutlined, FilePdfOutlined, MessageOutlined, ToolOutlined, FolderAddOutlined } from '@ant-design/icons';
const menuItems = {
  items: [
      {
          id: 'group-dashboard',
          title: 'Navigation',
          type: 'group',
          children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                icon: BarChartOutlined,
                breadcrumbs: false
            },
            {
                id: 'properties',
                title: 'Properties',
                type: 'item',
                url: '/properties',
                icon: ShopOutlined,
                breadcrumbs: true
            },
            {
                id: 'finances',
                title: 'Finances',
                type: 'item',
                url: '/finances',
                icon: DollarOutlined,
                breadcrumbs: true
            },
            {
                id: 'maintenance',
                title: 'Maintenance',
                type: 'item',
                url: '/maintenance',
                icon: ToolOutlined,
                breadcrumbs: false
            },
            {
                id: 'documents',
                title: 'Documents',
                type: 'item',
                url: '/documents',
                icon: FilePdfOutlined,
                breadcrumbs: false
            },
            {
                id: 'Messages',
                title: 'Messages',
                type: 'item',
                url: '/messages',
                icon: MessageOutlined,
                breadcrumbs: false
            },
          ]
      },
  ]
}

export default menuItems