import React, { useEffect, useState, lazy } from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import { Routes, Route, Outlet} from 'react-router-dom'

import Loadable from './components/Loadable'
import Breadcrumbs from './components/@extended/Breadcrumbs'
import navigation from './Drawer/MenuItems'


import {
  Box,
  Toolbar,
  useMediaQuery
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Drawer from './Drawer/index';
import Header from './Header/index';
import { openDrawer } from './store/reducers/menu';

import SignIn from './SignIn'

import { getAuth } from 'firebase/auth'


const Dashboard = Loadable(lazy(() => import('./Dashboard/index')))
const Properties = Loadable(lazy(() => import('./Properties')))
const Property = Loadable(lazy(() => import('./Property')))
const Maintenance = Loadable(lazy(() => import('./Maintenance')))
const MaintenanceOrder = Loadable(lazy(() => import('./MaintenanceOrder')))
const Documents = Loadable(lazy(() => import('./Documents')))
const Messages = Loadable(lazy(() => import('./Messages')))
const Finances = Loadable(lazy(() => import('./Finances')))
const SettingsAccount = Loadable(lazy(() => import('./SettingsAccount')))
const NotFound = Loadable(lazy(() => import('./NotFound')))

export default function App() {

  const AdminWrapper = () => {

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
      getAuth().onAuthStateChanged(function (user) {
        if (user) {
          setLoggedIn(true)
        } else {
          window.location.href = '/';
        }
      })
    }, [])

    if(loggedIn){
      return(
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <Toolbar />
            <Breadcrumbs navigation={navigation} title titlebottom="true" card="false" divider={false} />
            <Outlet />
          </Box>
        </Box>
      )
    }
    else{
      return null
    }
  }

  return (
    <Router>
      <Routes>

        <Route exact path="/" element={<SignIn/>}/>
        <Route path='*' element={<NotFound />} />

        <Route element={<AdminWrapper/>}>
          <Route exact path="/dashboard" element={<Dashboard/>} />
          <Route exact path="/properties" element={<Properties/>} />
          <Route exact path="/properties/:propertyId" element={<Property/>} />
          <Route exact path="/maintenance" element={<Maintenance/>} />
          <Route exact path="/maintenance/:orderId" element={<MaintenanceOrder/>} />
          <Route exact path="/finances" element={<Finances/>} />
          <Route exact path="/documents" element={<Documents/>} />
          <Route exact path="/messages" element={<Messages/>} />
          <Route exact path="/settings/account" element={<SettingsAccount/>} />
        </Route>
        
      </Routes>
    </Router>
  )
}