import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth'

import { 
  collection, 
  query, 
  where, 
  getDocs 
} from "firebase/firestore";

import {db} from './firebase';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cvmanagesproperties.com/">
        CV Manages Properties
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme()

export default function SignIn() {

  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState("")


  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
 
    const auth = getAuth()
    setPersistence(auth, browserSessionPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
        .then(async (user) => {
          const querySnapshot = await getDocs(query(collection(db, "admins"), where("adminId", "==", user.user.uid)))
          if(querySnapshot.size === 1){
            navigate("/dashboard", { replace: true })
          }
          else{
            setErrorMessage('Unable to find admin account with that email')
            setTimeout(() => setErrorMessage(''), 6000)
          }
        })
        .catch((error) => {
          const errorCode = error.code
          let errorMessage = ''
          switch(errorCode){
            case 'auth/invalid-email':
              errorMessage = 'Error: Invalid Email.'
              break;
            case 'auth/wrong-password':
              errorMessage = 'Error: Wrong Password.'
              break;
            case 'auth/user-not-found':
              errorMessage = 'Error: User Not Found.'
              break;
            default:
              errorMessage = 'Error'
          }
          setErrorMessage(errorMessage)
          setTimeout(() => setErrorMessage(''), 6000)
        })
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {errorMessage !== '' && (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}