// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCH1c5w2RfSibHlxOEOZTM4Tz1_5s7uEOs",
  authDomain: "cv-manages-properties-4680d.firebaseapp.com",
  projectId: "cv-manages-properties-4680d",
  storageBucket: "cv-manages-properties-4680d.appspot.com",
  messagingSenderId: "496177115188",
  appId: "1:496177115188:web:04cd0f2355cb8d7b759a61",
  measurementId: "G-BSMXWDBRKF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase
export const db = getFirestore(app);

// const analytics = getAnalytics(app);